<template>
  <div>
    <div class="h-100 bg-pinevento bg-animation">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <b-col md="20" class="mx-auto app-login-box">

          <div class="insight-logo mx-auto mb-3" />

          <div class="modal-dialog mx-auto">
            <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)" />
            <div class="modal-content">
              <div class="modal-body">
                <div class="h5 modal-title text-center">
                  <br>
                  <v-img src="/images/logo_login.png" width="100%" height="138" contain align="center"></v-img>
                  <br>
                  <h4 >
                    <div>Модераторски панел</div>
                  </h4>
                  <h6 class="mb-3 ml-3 mr3">
                    <span >Моля влезте със своя акаунт за да продължите.</span>
                  </h6>
                </div>
                <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field v-model="user.email" :rules="emailRules" label="E-mail" placeholder="Вашият e-mail..." required></v-text-field>
                <v-text-field type="password" :rules="passRules" v-model="user.password" label="Парола" placeholder="Вашата парола..." required></v-text-field>
<!--                <b-form-checkbox name="check" id="exampleCheck">Запомни ме</b-form-checkbox>-->
                </v-form>
              </div>
              <div class="modal-footer clearfix">
<!--                <div class="float-left">-->
<!--                  <a href="javascript:void(0);" class="btn-lg btn btn-link"> Забравена парола </a>-->
<!--                </div>-->
                <div class="float-right">
                  <b-button variant="primary" size="lg" :disabled="!valid"  v-on:click="requestPermission">Вход</b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center text-white opacity-8 mt-3">Copyright &copy; Insight Events Ltd</div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import User from '../models/user';
import ProfileService from "../services/profile.service";
import VueElementLoading from "vue-element-loading";
import swal from "sweetalert";
import Vue from "vue";

export default {
  name: 'Login',
  components: {
    VueElementLoading
  },
  data() {
    return {
      user: new User('', ''),
      message: '',
      valid: true,
      emailRules: [
        v => !!v || "Полето е задължително",
        v => /.+@.+/.test(v) || "Невалиден e-mail"
      ],
      passRules: [
        v => !!v || "Полето е задължително"
      ],
      showLoading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {
    handleLogin() {

        if (this.user.email && this.user.password)
        {
          this.showLoading = true;
          this.$store.dispatch('auth/login', this.user).then(
              () =>
              {
                ProfileService.setGCM(Vue.prototype.$firbaseToken).then(()=>{
                  ProfileService.getRemote().then(()=>{
                    this.$router.push('/events');
                  });
                });
                this.showLoading = false;
              },
              error =>
              {
                this.showLoading = false;
                this.message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                if(this.message.error.status_code==403)
                {
                  swal("", "Грешно потребителско име или парола", "error");
                }
                else
                {
                  swal("Грешка", this.message.error.message, "error");
                }

              }
          );
        }
        else
        {
          //Display error
          this.showLoading = false;
          swal("","Моля попълнете всички полета.","warning");
        }
    },
    requestPermission()
    {
      console.log('Requesting permission...');
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted')
        {
          Vue.prototype.$messaging.getToken({ vapidKey: Vue.prototype.$vapidKey  }).then((currentToken) => {
            if (currentToken) {
              Vue.prototype.$firbaseToken = currentToken
              this.handleLogin()
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          })
        }
        else
        {
          swal("", "Моля разрешете получаването на Push нотификации в браузъра си за да продължите. Разрешението е ключово за работата на апликацията", "error");
        }
      });
    }
  }
};
</script>